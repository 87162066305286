/** THIS IS NOT THE FILE YOU ARE LOOKING FOR */
/*  This file exists for legacy purposes only */
/*  There is now an embed-direqt-ai project which */
/*  contains our entire embed script along with our custom elements */
/*  if you're looking in here, you probably meant to look in there */

const direqt_local = import.meta.env.VITE_DIREQT_LOCAL === 'true';

const initBetaEmbed = (botId?: string) => {
    console.log('[DIREQT]: Legacy setup detected. Loading current embed.js...');
    const betaBotId =
        botId ??
        document.cookie
            .split('; ')
            .find(r => r.startsWith('direqtBetaBotId='))
            ?.split('=')[1];

    const script = document.createElement('script');
    script.setAttribute('type', 'module');
    script.toggleAttribute('async');
    const embedUrl = direqt_local
        ? `http://localhost:5173/src/embed.ts?inject=false`
        : `https://embed.direqt.ai/embed.js?inject=false`;
    if (betaBotId) {
        script.setAttribute('src', `${embedUrl}?id=${betaBotId}`);
    } else {
        script.setAttribute('src', embedUrl);
    }
    document.head.appendChild(script);
    console.log('[DIREQT]: New embed.js loaded. Enjoy Direqt!');

    return true;
};

if (document.readyState === 'loading') {
    const chatUrl = direqt_local
        ? 'http://localhost:8080'
        : 'https://chat.direqt.ai';
    fetch(chatUrl, {
        method: 'POST',
        body: JSON.stringify({ event: 'direqt-legacy-embed-loaded' }),
    });
    window.addEventListener(
        'DOMContentLoaded',
        async () => {
            initBetaEmbed();
        },
        { once: true }
    );
} else {
    initBetaEmbed();
}
